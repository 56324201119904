import React, {Component, useEffect} from "react";
import {useForm} from "react-hook-form";

//import Select from 'react-select'
import {useLocation} from '@reach/router';
import {Link, navigate} from "gatsby"
//import {swipeTransition, clubs} from "../constants"
//import * as styles from '../styles/index.module.scss'

import Layout from '../components/layout'
import {GlobalDispatchContext, GlobalStateContext} from '../context/context'

const ForgottenPassword = () => {

  const dispatch = React.useContext(GlobalDispatchContext);
  const state = React.useContext(GlobalStateContext);

  const {register, handleSubmit, watch, formState: {
    errors
  }} = useForm();
  const onSubmit = (data) => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email:data.username })
    };            
    fetch("https://lead5050.staging.parachute.net/?rest_route=/simple-jwt-login/v1/user/reset_password",requestOptions)
    .then(response => response.json())
    .then(data => {
      console.log(data);
      if (data.success == "true"){
        dispatch({type: "UPDATE_LOGIN_ERROR", payload: "Your password has been reset. Please check your inbox for details"})
      }else{
        dispatch({type: "UPDATE_LOGIN_ERROR", payload: "Something went wrong. Are you sure you're a user?"})
      }
    }).catch((error) => {

    })
  };

const renderLoginError =(data) => {

  if (state != null) {

  if (state.loginError != null && state.loginError != ""){
    return(
    <div className="error-box">
      {state.loginError}
    </div>
  )
    }
}
}
if (state != null) {

  return (

    <Layout pageTitle="Home Page">

<div className="container">
	<div className="row">
		<div className="col-md-12">
			
			<div className="container">
				<div className="row justify-content-center align-items-center">
					<div className="col-md-9">
						<div className="user-content text-center block-padding-medium">
							<h1>Forgotten Password</h1>
						</div>
					</div>
				</div>
			</div>


        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="gform-body">
            <div className="gform-fields">
       
     
        <div className="common-background row">

{renderLoginError()}
<div className="gfield col-md-12">
<label>Email Address</label>

        <input
            
              defaultValue={state.username}
              type="text"
              placeholder="Email Address"
              {...register("username", { required: true })}/> {errors.username && <span >this field is required</span>}
     </div>
  

      </div>
      </div>
      </div>
      <div className="col-md-12 text-center">
      <button>Submit</button>
      </div>
        </form>

        </div>
	</div>
</div> 


    </Layout>
  ) 
}
}
export default ForgottenPassword